import React from "react";
import BlogPage from "./BlogPage";

const Blog = () => {
  return <BlogPage />;
};

export const Head = () => <title>Blog</title>;

export default Blog;
